/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { wrapWithProvider } from 'src/components/base/globalState';

// Used for the eligibility checker typeahead styling
import 'bootstrap/dist/css/bootstrap.min.css';

// Not currently using anything from the Bootstrap JS, but can add it in future
// import 'bootstrap/dist/js/bootstrap.min.js';
// import '@popperjs/core/dist/umd/popper.min.js';

// Tell CallRail to rescan the page for phone numbers on every page transition.
// https://apidocs.callrail.com/#dynamic-number-insertion
export const onRouteUpdate = () => window.CallTrk?.swap();

export const wrapRootElement = wrapWithProvider;

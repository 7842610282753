import React, { useContext, useState } from 'react';

export const context = React.createContext();

/**
 * Gatsby doesn't have a top-level App, so use this wrapper to create global state.
 * As the site gets bigger, this could use redux or mobx, for now, seems sufficient to
 * just use top-level state made available via a context.
 */
const Provider = ({ children }) => {
  const [state, setState] = useState();

  // 'self-pay' | 'insurance' | 'unsure'
  const [paymentType, setPaymentType] = useState();

  // the selected dato payor
  const [payor, setPayor] = useState();

  // object w/ referrer info
  const [patientReferrer, setPatientReferrer] = useState(null);

  // a valid Referral Code allows automatic eligibility to enroll
  const [referralCode, setReferralCode] = useState(null);

  return (
    <context.Provider
      value={{
        state,
        setState,
        paymentType,
        setPaymentType,
        payor,
        setPayor,
        patientReferrer,
        setPatientReferrer,
        referralCode,
        setReferralCode
      }}
    >
      {children}
    </context.Provider>
  );
};

/**
 * React hook for accessing global state variables, eg
 * const { state, setState } = useGlobalState();
 */
export function useGlobalState() {
  return useContext(context);
}

/**
 * Wraps an element with our Provider, suitable for use as gatsby-ssr or gatsby-browser's
 * wrapRootElement, which is how this actually gets applied.
 */
export const wrapWithProvider = ({ element }) => <Provider>{element}</Provider>;
